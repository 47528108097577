import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons"
import clsx from "clsx"

import "../styles/podcast.css"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Podcast } from "../types"
import PodcastCard from "../components/PodcastCard"
import Button from "../components/Button"
import OnScreen from "../components/OnScreen"

interface PageContext {
  currentPage: number
  numPages: number
}

interface Props {
  data: any
  pageContext: PageContext
}

// TODO: get new social icons for hero
const PodcastPage: FunctionComponent<Props> = ({ data, pageContext }) => {
  const latestEpisode = data.latestEpisode.edges[0].node as Podcast
  const episodes = data.allContentfulPodcast.edges.map(
    (elem: any) => elem.node
  ) as Podcast[]

  const [activePodcastId, setActivePodcastId] = useState("")
  const [showForm, setShowForm] = useState(false)
  const [duration, setDuration] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [seekPosition, setSeekPosition] = useState(0)
  const [timeElapsed, setTimeElapsed] = useState("00:00:00")
  const [searchTerm, setSearchTerm] = useState("")
  const [searchResult, setSearchResult] = useState<Podcast[] | null>(episodes)

  const playerRef = useRef<HTMLAudioElement | null>(null)

  const formatTime = useCallback((time: number) => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time - hours * 3600) / 60)
    const seconds = Math.floor(time - hours * 3600 - minutes * 60)

    return `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}`
  }, [])

  useEffect(() => {
    function onSeek(event: Event) {
      const target = event.target as HTMLAudioElement

      setSeekPosition((target.currentTime / target.duration) * 100)
      setTimeElapsed(formatTime(target.currentTime))
    }

    const player = playerRef.current

    if (player) {
      player.addEventListener("timeupdate", onSeek)
    }

    return () => {
      if (player) {
        player.removeEventListener("timeupdate", onSeek)
      }
    }
  }, [formatTime])

  function onPlayClick() {
    if (playerRef.current) {
      setIsPlaying(!isPlaying)

      if (isPlaying) {
        playerRef.current.pause()
        setActivePodcastId("")
      } else {
        playerRef.current.play()
        setActivePodcastId(latestEpisode.id)
      }
    }
  }
  // TODO: Use with AllAnchorEpisode content field  to get description
  // function getDescription(str) {
  //   return str.match(/<p>(.*?)<\/p>/).map(val => val.replace(/<\/?p>/g, ""))[0]
  // }

  const handleSearch = (value: any) => {
    setSearchTerm(value)
    if (value !== "") {
      // let result = episodes.filter((episode) => episode.title.toLowerCase().startsWith(value.toLowerCase()))
      let result = episodes.filter(episode =>
        episode.title.toLowerCase().includes(value.toLowerCase())
      )
      setSearchResult(result)
    } else {
      setSearchResult(episodes)
    }
  }

  return (
    <Layout absoluteHeader>
      <SEO
        title={"Gamify your Life Podcast"}
        description="Explore the world of gamification in our podcast, delving into its impact on education, business, and personal growth. Tune in for expert insights!"
        link="https://mooremomentum.com/podcast/"
      />

      <div className="overflow-hidden">
        <div className="relative">
          <div className="hidden lg:block">
            <StaticImage
              alt="Podcasts Mooremomentum"
              className="hero"
              placeholder="blurred"
              src="../images/podcast-hero.png"
            />
          </div>

          <div className="sm:hidden">
            <StaticImage
              alt="Podcasts Mooremomentum"
              className="hero"
              placeholder="blurred"
              src="../images/podcast-hero-mobile.png"
            />
          </div>

          <div className="hidden sm:block lg:hidden">
            <StaticImage
              alt="Will Moore Standing on mountaintop"
              className="hero"
              placeholder="blurred"
              src="../images/podcast-hero-tablet.png"
            />
          </div>

          <div className="absolute flex inset-0 items-center justify-center">
            <div className="px-4 relative text-center text-white">
              <p className="mb-4 uppercase">Welcome to</p>

              <h1 className="font-Orbitron text-4xl md:text-5xl lg:text-6xl">
                The Gamify Your Life Podcast
              </h1>

              <StaticImage
                alt="Will Moore Standing on mountaintop"
                className="w-36"
                placeholder="blurred"
                src="../images/signature.png"
              />

              <p className="mb-4 mt-12 uppercase">Listen &amp; Subscribe On</p>

              <div className="gap-8 grid grid-cols-2 sm:grid-cols-4">
                <div className="bg-white flex h-16 items-center justify-center p-1 rounded-md shadow-lg w-full">
                  <a href="https://podcasts.apple.com/us/podcast/gamify-your-life/id1510028611">
                    <StaticImage
                      alt="Apple Podcast Logo"
                      className="max-h-12"
                      src="../images/apple-podcasts.png"
                    />
                  </a>
                </div>

                <div className="bg-white flex h-16 items-center justify-center p-1 rounded-md shadow-lg w-full">
                  <a href="https://www.youtube.com/channel/UCBBnybi_zZquxqB9rZIP6Zw">
                    <StaticImage
                      alt="Youtube Logo"
                      className="max-h-12"
                      src="../images/youtube.png"
                    />
                  </a>
                </div>

                <div className="bg-white flex h-16 items-center justify-center p-1 rounded-md shadow-lg w-full">
                  <a href="https://open.spotify.com/show/5TW9qSsbO23gsWuRX2mTYl">
                    <StaticImage
                      alt="Spotify Logo"
                      className="max-h-12"
                      src="../images/spotify.png"
                    />
                  </a>
                </div>

                {/* <div className="bg-white flex h-16 items-center justify-center rounded-md shadow-lg w-full">
                  <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8xZDk4Zjc4NC9wb2RjYXN0L3Jzcw==">
                    <StaticImage
                      alt="Google Podcast Logo"
                      className="max-h-12"
                      src="../images/google-podcasts.png"
                    />
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden px-4">
        <OnScreen
          className="max-w-6xl mx-auto my-10 md:my-16"
          classToAdd="slide-in-left"
        >
          {!showForm ? (
            <div className="flex justify-center items-center">
              <button
                onClick={() => setShowForm(!showForm)}
                className="bg-coral animate-pulse font-Orbitron text-white rounded-xl px-6 py-6 text-2xl md:text-3xl lg:text-4xl"
              >
                {/* Want to be my next guest? */}
                Apply To Be A Guest
              </button>
            </div>
          ) : (
            <div className="bg-coral px-4 py-8 relative rounded-xl shadow-lg">
              <div className="mx-auto space-y-8 text-center text-white xl:w-2/3">
                <h2 className="font-Orbitron text-2xl md:text-3xl lg:text-4xl">
                  {/* Want to be my next guest? */}
                  Apply To Be A Guest
                </h2>

                <p>
                  Do you have a story that you want to share and inspire others
                  with? Fill out the form below and apply to be our next guest
                  on The Gamify Your Life Podcast!
                </p>

                <form
                  name="Podcast-Guest"
                  method="POST"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                  className="text-black"
                >
                  <input type="hidden" name="form-name" value="Podcast-Guest" />
                  <input type="hidden" name="bot-field" />

                  <div className="space-y-8">
                    <div className="gap-8 grid sm:grid-cols-2">
                      <div>
                        <input
                          className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                          name="first-name"
                          placeholder="First Name"
                          required
                          type="text"
                        />
                      </div>

                      <div>
                        <input
                          className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                          name="last-name"
                          placeholder="Last Name"
                          required
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="gap-8 grid sm:grid-cols-2">
                      <div>
                        <input
                          className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                          name="email-address"
                          placeholder="Email Address"
                          required
                          type="email"
                        />
                      </div>

                      <div>
                        <input
                          className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                          name="phone-number"
                          placeholder="Phone Number"
                          required
                          type="tel"
                        />
                      </div>

                      <div>
                        <input
                          className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                          name="instagram"
                          placeholder="Link to Instagram"
                          required
                          type="url"
                        />
                      </div>

                      <div>
                        <input
                          className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                          name="website"
                          placeholder="Link to website"
                          required
                          type="url"
                        />
                      </div>
                    </div>

                    <div>
                      <textarea
                        className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                        name="message"
                        placeholder="Provide a short bio and let me know why you would be a good guest."
                        rows={8}
                      />
                    </div>

                    <Button className="px-12" type="submit" theme="WHITE">
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </OnScreen>
      </div>

      <div className="max-w-6xl mb-16 mx-auto px-4">
        <form className="mb-10">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <input
              className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              type="search"
              id="blog-search"
              placeholder="Search Blogs"
              required
              value={searchTerm}
              onChange={e => handleSearch(e.target.value)}
            />
            {/* <button type="submit" className="text-white absolute right-2.5 bottom-2.5 bg-blue hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2">Search</button> */}
          </div>
        </form>

        <h2 className="font-Orbitron mb-8 text-center text-2xl md:text-4xl">
          Most Recent Gamify Your Life Podcast Episodes
        </h2>

        <div className="gap-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {searchResult &&
            searchResult.map(elem => (
              <OnScreen key={elem.id} classToAdd="scale-in-center">
                <PodcastCard
                  activePodcastId={activePodcastId}
                  podcast={elem}
                  onPodcastPlayStateChanged={setActivePodcastId}
                />
              </OnScreen>
            ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int, $limit: Int) {
    latestEpisode: allContentfulPodcast(
      limit: 1
      sort: { fields: [publishedDate], order: DESC }
    ) {
      edges {
        node {
          blurb
          coverImage {
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
          id
          mediaUrl
          publishedDate
          slug
          title
        }
      }
    }
    allContentfulPodcast(
      skip: $skip
      limit: $limit
      sort: { fields: [publishedDate], order: DESC }
    ) {
      edges {
        node {
          blurb
          coverImage {
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
          id
          mediaUrl
          publishedDate
          slug
          title
        }
      }
    }

    allAnchorEpisode(
      skip: $skip
      limit: $limit
      sort: { order: DESC, fields: isoDate }
    ) {
      nodes {
        itunes {
          image
          episode
        }
        title
        content
        publishDate: isoDate(formatString: "DD, MMM, YYYY")
        daysAgo: isoDate(fromNow: true)
        id
        guid
        enclosure {
          url
          length
          type
        }
      }
    }
  }
`

export default PodcastPage
